import {HomeIcon} from '../../../components/core/icons';

// eslint-disable-next-line import/prefer-default-export
export const tabsData = [
    {
        id: 'home',
        title: 'Home',
        icon: HomeIcon,
        to: '/',
    },
];
